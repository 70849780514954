import React, { Suspense, lazy } from 'react';
import ReactRender from '../tools/react-render';
import ClientReviews from './ClientReviews';
import VideoReviews from './ClientReviews/VideoReviews';
import Search from './Search';
import Booking from './Booking';
import FAQs from './FAQs';
import { FindACentreMap, LocationMap } from './Maps';

const clientReviewsTarget = document.querySelector(
    '[data-behaviour="react-client-reviews"]',
);

ReactRender(
    <ClientReviews filterHeader={clientReviewsTarget?.innerHTML} />,
    clientReviewsTarget,
);

const searchTarget = document.querySelector('[data-behaviour="react-search"]');

if (searchTarget) {
    const searchQuery = searchTarget.dataset.query;
    ReactRender(<Search searchQuery={searchQuery} />, searchTarget);
}

const bookingTarget = document.querySelector(
    '[data-behaviour="react-booking"]',
);

if (bookingTarget) {
    const geo = {
        country: bookingTarget.dataset.country,
        currency: bookingTarget.dataset.currency,
        timezone: bookingTarget.dataset.timezone,
    };
    ReactRender(<Booking geo={geo} />, bookingTarget);
}

const faqsTarget = document.querySelector(
    '[data-behaviour="react-filter-faqs"]',
);

if (faqsTarget) {
    const faqsTitle =
        'title' in faqsTarget.dataset ? faqsTarget.dataset.title : false;
    ReactRender(<FAQs title={faqsTitle} />, faqsTarget);
}

const findACentreEl = document.querySelector(
    '[data-behaviour="google-map"][data-map-type="find-a-centre"]',
);

if (findACentreEl) {
    const geo = {
        country: findACentreEl.dataset.country,
        currency: findACentreEl.dataset.currency,
        timezone: findACentreEl.dataset.timezone,
    };
    const noResultsTitle = findACentreEl.getAttribute('data-no-results-title');
    const noResultsText = findACentreEl.getAttribute('data-no-results-text');
    const noResultsServicesText = findACentreEl.getAttribute(
        'data-no-results-services-text',
    );
    const multiSiteId =
        parseInt(findACentreEl.getAttribute('data-multi-site-id')) ?? 1;

    ReactRender(
        <FindACentreMap
            noResultsTitle={noResultsTitle}
            noResultsText={noResultsText}
            noResultsServicesText={noResultsServicesText}
            multiSiteId={multiSiteId}
            geo={geo}
        />,
        findACentreEl,
    );
}

const locationMapEl = document.querySelector(
    '[data-behaviour="google-map"][data-map-type="single-location-map"]',
);

if (locationMapEl) {
    const geopoint = {
        lat: parseFloat(locationMapEl.dataset.lat),
        lng: parseFloat(locationMapEl.dataset.lng),
    };

    ReactRender(<LocationMap geopoint={geopoint} />, locationMapEl);
}

const clientVideoReviewsTarget = document.querySelector(
    '[data-behaviour="react-client-video-reviews"]',
);

ReactRender(
    <VideoReviews filterHeader={clientVideoReviewsTarget?.innerHTML} />,
    clientVideoReviewsTarget,
);

const seminarListingTargets = document.querySelectorAll(
    '[data-behaviour="seminar-listing"]',
);

if (seminarListingTargets) {
    const SeminarListing = lazy(() => import('./SeminarListing'));

    seminarListingTargets.forEach(target => {
        const geo = {
            country: target.dataset.country,
            currency: target.dataset.currency,
            timezone: target.dataset.timezone,
        };
        const addictions = JSON.parse(target.dataset.seminarAddictions);
        const type = target.dataset.seminarType;
        const locations = JSON.parse(target.dataset.seminarLocations);
        const couponCode = 'couponCode' in target.dataset ? target.dataset.couponCode : '';
        const priceOverride = 'priceOverride' in target.dataset ? target.dataset.priceOverride : '';
        const overrideText =  target.dataset.overrideText;
        const overrideOriginalText =  target.dataset.overrideOriginalText;
        const nhsForm = JSON.parse(target.dataset.nhsForm);

        const SeminarListingWrapper = () => (
            <>
                <Suspense fallback={<div>Loading...</div>}>
                    <SeminarListing
                        seminarAddictions={addictions}
                        seminarType={type}
                        seminarLocations={locations}
                        geo={geo}
                        couponCode={couponCode}
                        priceOverride={priceOverride}
                        overrideText={overrideText}
                        overrideOriginalText={overrideOriginalText}
                        nhsForm={nhsForm}
                    />
                </Suspense>
            </>
        );

        ReactRender(<SeminarListingWrapper />, target);
    });
}
